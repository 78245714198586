.brand {
    position: relative;
    display: flex;
    min-width: 6em;
    height: 6em;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    align-items: center;
    justify-content: center;
    & img {
        max-width: 85%;
        max-height: 85%;
        object-fit: cover;
    }
}

.true-brand{
    background-color: #0e4a5c;
}
.false-brand{
    background-color: rgba(232, 232, 232, 0.68);
}
