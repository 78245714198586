#main_footer {
    background-color: #fff;
    color: #333;
    padding: 3rem 0;
    text-align: center;
    width: 100%;
    margin-top: 5em;
    border-top: 1px solid #ddd;
    font-family: "Proxima Nova A", sans-serif;

}

.footer-content {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.footer-section {
    flex: 1 1 300px;
    padding: 2rem;
    text-align: left;
    border-right: 1px solid #ddd;
}

.footer-section h3 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #1F4F77;
}

.footer-section p {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
}

.footer-section ul {
    list-style: inside square;
    padding-left: 20px;
}

.footer-section ul li {
    margin: 0.75rem 0;
    font-size: 1rem;
    color: #333;
}

.footer-section ul li a {
    color: #61A0AF;
    text-decoration: none;
    font-size: 1rem;
    display: inline-block;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #4DA1A9;
    text-decoration: underline;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.social-links a {
    color: #1F4F77;
    margin: 0;
    font-size: 1.5rem;
    transition: transform 0.3s ease, color 0.3s ease;
}

.social-links a:hover {
    transform: scale(1.2);
    color: #4DA1A9;
}

.footer-bottom {
    margin-top: 3rem;
    font-size: 1rem;
    color: #bbb;
    text-align: center;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .footer-section {
        text-align: center;
        padding: 1.5rem;
        border-right: none;
    }
}
