.ProductCard{
    position: relative;
}
.add-product-cart-button {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background-color: #7e7d11;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 25px;
    cursor: pointer;
    transition: background-color 0.25s;
}
.add-product-cart-button:hover {
    background-color: #838282;
}
