.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background: url('../../assets/images/fondoHero.png') no-repeat center center/cover;
    color: #ffffff;
    text-align: center;
    padding: 2rem;
    margin-bottom: 2cap;
    width: 100%;
    overflow: hidden;
}

.decoration-hero-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background-color: rgba(192, 192, 192, 0.5);
    padding: 3em 5em;
}

.hero-content h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.hero-content p {
    color: #000;
    font-weight: 700;
    font-size: 2rem;
}

@media (width <= 800px) {
    .hero-content p {
        display: none;
    }
}
  