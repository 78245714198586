.contentModalProduct {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;
    max-width: 600px;
    margin: auto;
}

.modalHeader h2 {
    margin: 0;
    font-size: 2rem;
    color: #333;
}

.productDesc {
    font-size: 1rem;
    color: #666;
}

.productImages {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.mainImage {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageGallery {
    display: flex;
    gap: 0.5rem;
}

.thumbnailImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;
}

.thumbnailImage:hover {
    transform: scale(1.1);
}

.productDetails {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.detailRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #333;
}

.detailRow strong {
    font-weight: bold;
    color: #555;
}

.colorsRow {
    flex-direction: column;
    align-items: flex-start;
}

.colorList {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
}

.colorItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f9f9f9;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.colorDetails {
    display: flex;
    flex-direction: column;
}

.colorTemperature {
    font-size: 0.9rem;
    font-weight: bold;
    color: #0077cc;
}

.colorDescription {
    font-size: 0.85rem;
    color: #555;
}
