.containerBrandChooser{
    display: grid;
    overflow: hidden;
    grid-template-columns: 15% 70% 15%;
    width: 60em;
    max-width: 100%;

}

.brandChooser {
    position: relative;
    display: flex;
    gap: 1em;
    transition: left 0.35s;
}

.aux-brandChooser{
    overflow: hidden;

}