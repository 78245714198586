.btn-consult-whatsapp {
    background-color: #1F4F77;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0.8em 1.5em;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.btn-consult-whatsapp:hover {
    background-color: #136f89;
    box-shadow: 0 5px 15px rgba(19, 111, 137, 0.3);
}

.btn-consult-whatsapp:active {
    background-color: #0e4a5c;
    transform: scale(0.95);
}