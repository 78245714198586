.modal-p {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 50%;
    height: 80%;
    z-index: 11;
    overflow: scroll;
    min-width: 22em;
}