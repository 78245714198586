.product-page{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4em;
    align-items: center;
}

.products-title {
    font-size: 2rem;
    text-align: center;
  }
.product-body{
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 20% 80%;
    padding: 0 3em;
}

@media (max-width: 600px) {
    .product-body{
        display: flex;
        flex-direction: column;
    }
}


  