.content {
    text-align: center;
    background-color: #61A0AF;
    padding: 3.5rem 0 ;
    width: 60%;
    border-radius: 15px;
    box-shadow: -1px -2px 41px -11px rgba(0,0,0,0.12);
}

.content h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #000;
}

.content p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
    margin-right: 10%;
    margin-left: 10%;
    text-align: left;
    text-align: -webkit-left;
}

@media (max-width: 500px) {
    .content {
        width: 23em;
    }
}