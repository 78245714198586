.CartItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin: 10px 0;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
}

.CartItem:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.head-cart-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.cart-item-details {
    flex: 1;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cart-item-details strong {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
}

.cart-item-details p {
    font-size: 14px;
    color: #666;
    margin: 0;
    line-height: 1.4;
}

.cart-item-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
}

.QuantityComponent {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    background-color: #fff;
}

.QuantityComponent button {
    border: none;
    background: none;
    padding: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: color 0.2s ease;
}

.QuantityComponent button:hover {
    color: #007bff;
}

.QuantityComponent input {
    width: 30px;
    text-align: center;
    border: none;
    outline: none;
    font-size: 14px;
    margin: 0 5px;
    color: #333;
}

.QuantityComponent input:disabled {
    background-color: transparent;
}

@media (max-width: 768px) {
    .CartItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .cart-item-details {
        margin: 10px 0;
    }

    .cart-item-quantity {
        width: 100%;
        justify-content: flex-start;
    }
}
