.cardProduct {
    position: relative;
    display: grid;
    grid-template-rows:3fr 1fr 1fr;
    width: 15em;
    aspect-ratio: 2/3;
    background-color: #ffffff;
    box-shadow: 10px 10px 30px rgba(2, 2, 2, 0.3);
    cursor: pointer;
}



#imageProductContainer {
    position: relative;
    display: flex;
    padding:1em;

}
#imageProductContainer img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}

#nameDescContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5em;
    background-color: #9e424e; /* Color anterior: rgba(6, 86, 45, 0.29);*/
    color: white;
    font-family: "Proxima Nova Bold", sans-serif;
}

#utilsContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #595959;

    & div {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
