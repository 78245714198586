
#title-products {
    font-family: "Proxima Nova A", sans-serif;
    position: relative;
    display: flex;
    top: 0;
    background-color: #f7f7f7;
    color: #b0b0b0;
    width: 100%;
    margin: 0;
    padding: 2em;
    text-align: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #e0e0e0;

    & span {
        font-size: 1.2rem;
        font-weight: 300;
        letter-spacing: 0;
        text-transform: uppercase;
        margin-bottom: -1em;
    }

    & h1 {
        font-size: 2.5rem;
        font-weight: 700;
        color: #333333;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin: 0;
    }
}

.default {
    margin-top: 1em;
    width: 100%;
    display: flex;
    align-items: start;
    gap: 3em;
    justify-content: start;
    flex-direction: column;
}